@-webkit-keyframes grain {
    0%, 100% {
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      -moz-transform: translate(-5%, -10%);
      -ms-transform: translate(-5%, -10%);
      -webkit-transform: translate(-5%, -10%);
      transform: translate(-5%, -10%);
    }
    20% {
      -moz-transform: translate(-15%, 5%);
      -ms-transform: translate(-15%, 5%);
      -webkit-transform: translate(-15%, 5%);
      transform: translate(-15%, 5%);
    }
    30% {
      -moz-transform: translate(7%, -25%);
      -ms-transform: translate(7%, -25%);
      -webkit-transform: translate(7%, -25%);
      transform: translate(7%, -25%);
    }
    40% {
      -moz-transform: translate(-5%, 25%);
      -ms-transform: translate(-5%, 25%);
      -webkit-transform: translate(-5%, 25%);
      transform: translate(-5%, 25%);
    }
    50% {
      -moz-transform: translate(-15%, 10%);
      -ms-transform: translate(-15%, 10%);
      -webkit-transform: translate(-15%, 10%);
      transform: translate(-15%, 10%);
    }
    60% {
      -moz-transform: translate(15%, 0%);
      -ms-transform: translate(15%, 0%);
      -webkit-transform: translate(15%, 0%);
      transform: translate(15%, 0%);
    }
    70% {
      -moz-transform: translate(0%, 15%);
      -ms-transform: translate(0%, 15%);
      -webkit-transform: translate(0%, 15%);
      transform: translate(0%, 15%);
    }
    80% {
      -moz-transform: translate(3%, 35%);
      -ms-transform: translate(3%, 35%);
      -webkit-transform: translate(3%, 35%);
      transform: translate(3%, 35%);
    }
    90% {
      -moz-transform: translate(-10%, 10%);
      -ms-transform: translate(-10%, 10%);
      -webkit-transform: translate(-10%, 10%);
      transform: translate(-10%, 10%);
    }
  }
  @-moz-keyframes grain {
    0%, 100% {
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      -moz-transform: translate(-5%, -10%);
      -ms-transform: translate(-5%, -10%);
      -webkit-transform: translate(-5%, -10%);
      transform: translate(-5%, -10%);
    }
    20% {
      -moz-transform: translate(-15%, 5%);
      -ms-transform: translate(-15%, 5%);
      -webkit-transform: translate(-15%, 5%);
      transform: translate(-15%, 5%);
    }
    30% {
      -moz-transform: translate(7%, -25%);
      -ms-transform: translate(7%, -25%);
      -webkit-transform: translate(7%, -25%);
      transform: translate(7%, -25%);
    }
    40% {
      -moz-transform: translate(-5%, 25%);
      -ms-transform: translate(-5%, 25%);
      -webkit-transform: translate(-5%, 25%);
      transform: translate(-5%, 25%);
    }
    50% {
      -moz-transform: translate(-15%, 10%);
      -ms-transform: translate(-15%, 10%);
      -webkit-transform: translate(-15%, 10%);
      transform: translate(-15%, 10%);
    }
    60% {
      -moz-transform: translate(15%, 0%);
      -ms-transform: translate(15%, 0%);
      -webkit-transform: translate(15%, 0%);
      transform: translate(15%, 0%);
    }
    70% {
      -moz-transform: translate(0%, 15%);
      -ms-transform: translate(0%, 15%);
      -webkit-transform: translate(0%, 15%);
      transform: translate(0%, 15%);
    }
    80% {
      -moz-transform: translate(3%, 35%);
      -ms-transform: translate(3%, 35%);
      -webkit-transform: translate(3%, 35%);
      transform: translate(3%, 35%);
    }
    90% {
      -moz-transform: translate(-10%, 10%);
      -ms-transform: translate(-10%, 10%);
      -webkit-transform: translate(-10%, 10%);
      transform: translate(-10%, 10%);
    }
  }
  @-ms-keyframes grain {
    0%, 100% {
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      -moz-transform: translate(-5%, -10%);
      -ms-transform: translate(-5%, -10%);
      -webkit-transform: translate(-5%, -10%);
      transform: translate(-5%, -10%);
    }
    20% {
      -moz-transform: translate(-15%, 5%);
      -ms-transform: translate(-15%, 5%);
      -webkit-transform: translate(-15%, 5%);
      transform: translate(-15%, 5%);
    }
    30% {
      -moz-transform: translate(7%, -25%);
      -ms-transform: translate(7%, -25%);
      -webkit-transform: translate(7%, -25%);
      transform: translate(7%, -25%);
    }
    40% {
      -moz-transform: translate(-5%, 25%);
      -ms-transform: translate(-5%, 25%);
      -webkit-transform: translate(-5%, 25%);
      transform: translate(-5%, 25%);
    }
    50% {
      -moz-transform: translate(-15%, 10%);
      -ms-transform: translate(-15%, 10%);
      -webkit-transform: translate(-15%, 10%);
      transform: translate(-15%, 10%);
    }
    60% {
      -moz-transform: translate(15%, 0%);
      -ms-transform: translate(15%, 0%);
      -webkit-transform: translate(15%, 0%);
      transform: translate(15%, 0%);
    }
    70% {
      -moz-transform: translate(0%, 15%);
      -ms-transform: translate(0%, 15%);
      -webkit-transform: translate(0%, 15%);
      transform: translate(0%, 15%);
    }
    80% {
      -moz-transform: translate(3%, 35%);
      -ms-transform: translate(3%, 35%);
      -webkit-transform: translate(3%, 35%);
      transform: translate(3%, 35%);
    }
    90% {
      -moz-transform: translate(-10%, 10%);
      -ms-transform: translate(-10%, 10%);
      -webkit-transform: translate(-10%, 10%);
      transform: translate(-10%, 10%);
    }
  }
  @keyframes grain {
    0%, 100% {
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    10% {
      -moz-transform: translate(-5%, -10%);
      -ms-transform: translate(-5%, -10%);
      -webkit-transform: translate(-5%, -10%);
      transform: translate(-5%, -10%);
    }
    20% {
      -moz-transform: translate(-15%, 5%);
      -ms-transform: translate(-15%, 5%);
      -webkit-transform: translate(-15%, 5%);
      transform: translate(-15%, 5%);
    }
    30% {
      -moz-transform: translate(7%, -25%);
      -ms-transform: translate(7%, -25%);
      -webkit-transform: translate(7%, -25%);
      transform: translate(7%, -25%);
    }
    40% {
      -moz-transform: translate(-5%, 25%);
      -ms-transform: translate(-5%, 25%);
      -webkit-transform: translate(-5%, 25%);
      transform: translate(-5%, 25%);
    }
    50% {
      -moz-transform: translate(-15%, 10%);
      -ms-transform: translate(-15%, 10%);
      -webkit-transform: translate(-15%, 10%);
      transform: translate(-15%, 10%);
    }
    60% {
      -moz-transform: translate(15%, 0%);
      -ms-transform: translate(15%, 0%);
      -webkit-transform: translate(15%, 0%);
      transform: translate(15%, 0%);
    }
    70% {
      -moz-transform: translate(0%, 15%);
      -ms-transform: translate(0%, 15%);
      -webkit-transform: translate(0%, 15%);
      transform: translate(0%, 15%);
    }
    80% {
      -moz-transform: translate(3%, 35%);
      -ms-transform: translate(3%, 35%);
      -webkit-transform: translate(3%, 35%);
      transform: translate(3%, 35%);
    }
    90% {
      -moz-transform: translate(-10%, 10%);
      -ms-transform: translate(-10%, 10%);
      -webkit-transform: translate(-10%, 10%);
      transform: translate(-10%, 10%);
    }
  }