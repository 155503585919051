    input[type=checkbox] {
        transition: all 0.3s;
        box-sizing: border-box;
        display: none;
    }
    .sidebar-menu { /* on mobile cover whole screen with modal style pop-over */
        position: absolute;
        top: 0px;
        left: -200px;
        width: 200px;
        height: 100%;
        z-index: 200;
    }
    .sidebar-menu-inner {
        width: 100%;
        height: 100%;
        background-image: url('/images/sidebar/sidebg.png');
    }
    .sidebar-menu-text > ul{
        position: relative;
        padding-top: 40px;
    }
    .sidebar-menu-text > ul > li{
        position: relative;
        display: block;
    }
    .sidebar-menu-enter {
        left: 0%;
    }
    .sidebar-menu-exit {
        left: -100%;
    }
    .sidebar-toggle {
        box-sizing: border-box;
        cursor: url("/images/cursorpoint.png"), auto;
        position: fixed;
        z-index: 300;
        height: 100%;
        width: 100%;
        top: 7px;
        left: 15px;
        height: 22px;
        width: 22px;
    }
    .sidebar-toggle-enter {
        transition: all 1s ease 0.3s;
        left: 150px;
    }
    .sidebar-toggle-exit {
        transition: all 1s ease;
        left: 15px;
    }
    .spinner {
        transition: all 0.3s;
        box-sizing: border-box;
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: #000;
    }
    .horizontal {
        transition: all 0.3s;
        box-sizing: border-box;
        position: relative;
        float: left;
        margin-top: 3px;
    }
    .diagonal.part-1 {
        position: relative;
        transition: all 0.3s;
        box-sizing: border-box;
        float: left;
    }
    .diagonal.part-2 {
        transition: all 0.3s;
        box-sizing: border-box;
        position: relative;
        float: left;
        margin-top: 3px;
    }
    input[type=checkbox]:checked ~ .sidebar-toggle > .horizontal {
        transition: all 0.3s;
        box-sizing: border-box;
        opacity: 0;
    }
    input[type=checkbox]:checked ~ .sidebar-toggle > .diagonal.part-1 {
        transition: all 0.3s;
        box-sizing: border-box;
        transform: rotate(135deg);
        margin-top: 8px;
    }
    input[type=checkbox]:checked ~ .sidebar-toggle > .diagonal.part-2 {
        transition: all 0.3s;
        box-sizing: border-box;
        transform: rotate(-135deg);
        margin-top: -9px;
    }
    .link-container {
        position: relative;
        padding-right: 20px;
        width: 100%;
    }
    .link-container-inner {
        position: relative;
        width: 100%;
        height: 88px;
    }
    .link-children {
        position: absolute;
        width: 100%;
        height: 88px;
    }
    .link-text {
        font-family: Titan One;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 201;
        padding-bottom: 10px;
    }
    .link-text > p {
        color: #fcfcfc;
        text-decoration: none;
        text-shadow: 2px 1px 0px rgba(0, 0, 0, 1);
        padding-left: 5px;
    }
    .link-image {
        z-index: 200;
    }