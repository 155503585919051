@import url("./Keyframes.css");

@font-face {
  font-family: 'Yellowtail';
  src: url('/fonts/Yellowtail-Regular.ttf') format('embedded-opentype'), /* Internet Explorer */
  url('/fonts/Yellowtail-Regular.ttf') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Yellowtail-Regular.ttf') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/Yellowtail-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Yellowtail-Regular.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Rhinemetal';
  src: url('/fonts/1952 RHEINMETALL.ttf') format('embedded-opentype'), /* Internet Explorer */
  url('/fonts/1952 RHEINMETALL.ttf') format('woff2'), /* Super Modern Browsers */
  url('/fonts/1952 RHEINMETALL.ttf') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/1952 RHEINMETALL.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/fonts/1952 RHEINMETALL.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Boogaloo';
  src: url('/fonts/Boogaloo-Regular.otf') format('embedded-opentype'), /* Internet Explorer */
  url('/fonts/Boogaloo-Regular.otf') format('woff2'), /* Super Modern Browsers */
  url('/fonts/Boogaloo-Regular.otf') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/Boogaloo-Regular.otf')  format('truetype'), /* Safari, Android, iOS */
  url('/fonts/Boogaloo-Regular.otf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Titan One';
  src: url('/fonts/TitanOne-Regular.ttf') format('embedded-opentype'), /* Internet Explorer */
  url('/fonts/TitanOne-Regular.ttf') format('woff2'), /* Super Modern Browsers */
  url('/fonts/TitanOne-Regular.ttf') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/TitanOne-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('/fonts/TitanOne-Regular.ttf') format('svg'); /* Legacy iOS */
}

/* Global */

p {
  margin-bottom: 5px;
}

.container {
  display: block;
  max-width: 100%!important;
}

.click-pointer {
  cursor: url("/images/cursorpoint.png"), auto;
}

body {
  background-image: url("/images/bg.jpg");
  overflow: hidden;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.14285714rem;
  max-width: 700px!important;
  line-height: 1.5;
}

.slides {
  width: 100%;
  height: calc(200% - 180px);
  max-height: calc(200% - 180px);
  position: relative;
  z-index: 0;
  overflow-y: scroll;
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
}
.float {
  float: right;
}
.start {
  position: absolute;
  top: -100%;
  right: 0px;
}
.end {
  position: absolute;
  top: 0px;
  left: 0px;
}

.about {
  z-index: 51;
}
.about-more {
  z-index: 52
}
.project {
  z-index: 53;
}
.contact {
  z-index: 54;
}

@media screen and (min-width: 360px) {
  .slides {
    height: calc(200% - 240px);
    max-height: calc(200% - 240px);
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: auto!important;
    margin-left: 1em!important;
    margin-right: 1em!important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: 723px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 933px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    width: 1127px;
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

/* Overlay */

.overlay {
  position: absolute;
  pointer-events: none;
  opacity: 0.2;
  top: -2000px;
  left: -2000px;
  width: 7680px;
  min-width: 7680px;
  height: 3240px;
  z-index: 1000;
}


.two {
  background-image: url("/images/grain.png");
  -webkit-animation: grain 20s steps(10) infinite;
  -moz-animation: grain 20s steps(10) infinite;
  -ms-animation: grain 20s steps(10) infinite;
  animation: grain 20s steps(10) infinite;
  background-size: cover;
}

/* Primary */

.primary-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

/* Splash */

.splash {
  width: 100%;
  height: 100%;
  max-height: 100%;
  text-align: center;
}

.splash-header {
  position: relative;
  height: 140px;
  max-height: 140px;
}

hr {
  position: absolute;
  bottom: 0px;
  display: block;
  opacity: 0.5;
  border: 0px;
  width: 100%;
  height: 10px;
  outline: none;
  background-image: url("/images/customhr.png");
}

.main-heading {
  font-family: Yellowtail;
}

.splash-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-heading {
  font-size: 60px;
}
.splash-image {
  width: 300px;
}

@media screen and (min-width: 360px) and (max-width: 899px) {
  .main-heading {
    font-size: 60px;
  }
  .splash-image {
    width: 340px;
  }
}

@media screen and (min-width: 768px) {
  .splash-header {
    height: 200px;
    max-height: 200px;
  }
}

@media screen and (min-width: 900px) { /* Once the image reaches this screen size, don't want it to grow anymore */
  /* .main-heading {
    font-size: 80px;
  }
  .splash-image {
    max-width: 450px;
  } */
}

/* About */

.about-barker-container, .about-barker-text {
  width: 320px;
  height: 278px;
  font-size: .9em;
}

.about-barker-container {
  position: relative;
  background-size: 320px;
  background-image: url("/images/aboutbarker.png");
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
}
.about-barker-text {
  font-family: Rhinemetal;
  text-align: justify;
  font-weight: 600;
  padding-right: 6%;
  padding-left: 6%;
}
.about-more-container {
  position: relative;
  width: 320px;
  height: 278px;
  font-size: .9em;
  background-size: 320px;
  background-image: url("/images/aboutmore.png");
  margin-left: auto;
  margin-right: auto;
  z-index: 20;
}
.about-more-text {
  font-family: Rhinemetal;
  text-align: justify;
  font-weight: 600;
  padding-right: 3%;
  padding-left: 3%;
  height: 70%;
  overflow-y: scroll;
  scrollbar-width: none; /* FireFox */
  -ms-overflow-style: none; /* Edge + IE */
}

.about-more-text::-webkit-scrollbar { /* WebKit */
  display: none;
}

.about-more-banner {
  display: block;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.space-one {
  width: 37%;
  height: 16%;
  float: left;
}
.space-two {
  width: 63%;
  height: 11%;
  float: right;
}
.space-three {
  width: 2%;
  height: 36%;
  float: left;
  clear: left;
}
.space-four {
  width: 32%;
  height: 19%;
  float: left;
  clear: left;
}
.space-five {
  width: 43%;
  height: 14%;
  float: left;
  clear: left;
}

.click-more {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
}

.click-for-projects {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40%;
}

.click-for-contact {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
}


/* Projects */
.project-card-container {
  position: relative;
  background-image: url("/images/projectsbarkerupd.png");
  background-size: 320px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 320px;
  min-height: 500px;
  z-index: 20;
}
.project-card-text {
  font-family: Rhinemetal;
  text-align: justify;
  font-size: .9em;
  font-weight: 600;
  width: 100%;
  padding-top: 70%;
  padding-left: 8%;
  padding-right: 8%;
}
  .project-card-bullets-container {
    position: relative;
    width: 100%;
    font-family: Boogaloo;
    font-size: 14px;
  }
  .project-bullets-text {
    position: absolute;
    padding-top: 8%;
    padding-left: 10%;
    padding-right: 28%;
    width: 80%;
    height: 20%;
    left: 3%;
    top: 2%;
    z-index: 50;
  }
  .built-using {
    position: absolute;
    width: 62%;
    left: 3%;
    top: 2%;
    z-index: 30;
  }
  .project-heading {
    position: absolute;
    width: 58%;
    left: 0px;
    top: 24%;
  }
  .screenshots-button {
    position: absolute;
    width: 44%;
    right: 0px;
    top: 24%;
  }

  /* Buttons */

  .info-buttons {
    position: absolute;
    top: 38%;
  }
  .gitbox {
    left: 10%;
    width: 20%;
  }

  .liveapp {
    left: 36%;
    width: 20%;
  }
  
  .inner-next {
    position: absolute;
    bottom: 6%;
    right: 45%;
    width: 18%;
  }

  .inner-prev {
    position: absolute;
    bottom: 3%;
    right: 68%;
    width: 25%;
  }

  /* Contact */

  .contact-container {
    width: 320px;
    background-image: url("/images/contactme.png");
    background-size: 320px;
    height: 408px;
    margin-left: auto;
    margin-right: auto;
    z-index: 20;
  }

  /* Footer */
  .footer-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    background-image: url('/images/footerbg.png');
    z-index: 900;
  }
  .footer-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    width: 100%;
    height: 40px;
    z-index: 900;
  }
  .footer-images {
    padding-right: 50px;
  }